"use strict";

import Swiper from 'swiper';
import {loadImg} from "./lazy-img";
import {onEnterViewPort} from "@elements/viewport-utils";

const defaultSelectors = {
    sliderContainer: '.js-swiper-img-text',
    slider: '.js-swiper__slider',
    sliderScrollbar: '.js-swiper__scrollbar',
    sliderPrev: '.js-swiper__prev',
    sliderNex: '.js-swiper__next',
    sliderStartStop: '.js-swiper__start-stop'
};

export function createInitInScope(selectors = defaultSelectors, options) {
    return function ($scope) {
        let $sliderElements = $scope.find(selectors.slider);
        let $sliderContainer = $sliderElements.closest(selectors.sliderContainer);

        $sliderContainer.each(function () {

            let $slider = $(this).find(selectors.slider);

            let sliderOptions = {
                keyboard: {
                    enabled: true,
                },
                on: { // Secondary pagination is update after initialization and after slide change
                    init:        updSwiperNumericPagination,
                    slideChange: updSwiperNumericPagination
                },
                ...(transformOptions(options,$(this)))
            };

            const $tabPaneParent = $slider.closest('.tab-pane:not(.active)');
            const $collapseParent = $slider.closest('.collapse');
            const $modalParent = $slider.closest('.modal');

            if ($tabPaneParent && $tabPaneParent.length) {
                /*wait for the initializiation of the slider if it is hidden in a tab */
                const $tabTrigger = $('[href="#' + $tabPaneParent.attr('id') + '"],[data-toggle=tab][data-target="#' + $tabPaneParent.attr('id') + '"]');

                $tabTrigger.one('shown.bs.tab', function () {
                    initSlider($slider, sliderOptions);
                });

            } else if ($collapseParent && $collapseParent.length) {
                /* wait until slide is shown if it is initially hidden in a collapse/accordion */
                $collapseParent.one('shown.bs.collapse', function () {
                    initSlider($slider, sliderOptions);
                });

            } else if ($modalParent && $modalParent.length) {
                /* wait until slide is shown if it is initially  hidden in a modal */
                $modalParent.one('shown.bs.modal', function () {
                    initSlider($slider, sliderOptions);
                });

            } else {
                // no tab, accordion, modal -> init slider as usual
                initSlider($slider, sliderOptions);
            }
        });
        return $sliderElements;
    }
}

function updSwiperNumericPagination() {
    $(this.el).closest(defaultSelectors.sliderContainer).find( '.swiper-counter' )[0].innerHTML = '<span class="count">'+ (this.activeIndex + 1) +'</span>/<span class="total">'+ this.slides.length +'</span>';
}


const transformOptions = (options, $container) => {
    options = ({
        ...options,
        scrollbar: {...options.scrollbar},
        navigation: {...options.navigation},
    });

    if (options.scrollbar && options.scrollbar.findElement) {
        options.scrollbar.el = options.scrollbar.findElement($container);
    }

    if (options.navigation && options.navigation.findNextElement) {
        options.navigation.nextEl = options.navigation.findNextElement($container);
    }

    if (options.navigation && options.navigation.findPrevElement) {
        options.navigation.prevEl = options.navigation.findPrevElement($container);
    }

    if (options.pagination && options.pagination.findElement) {
        options.pagination.el = options.pagination.findElement($container);
    }

    return options
};


function initSlider($slider, sliderOptions) {
    // $slider.slick(sliderOptions).on('afterChange', function (event, slick, currentSlide) {
    //     preloadNSlide(slick, currentSlide + 1, sliderOptions.slidesToShow);
    // });

    // onEnterViewPort($slider, function ($slider) {
    //     preloadNSlide($slider.slick('getSlick'), 1, sliderOptions.slidesToShow);
    // });


    onEnterViewPort($slider, function ($slider) {
        let swiper = new Swiper($slider, sliderOptions);
        // loadImg($imgToPreload);


        console.log('$slider.find(defaultSelectors.sliderStartStop)',$slider.find(defaultSelectors.sliderStartStop));
        console.log('defaultSelectors.sliderStartStop',defaultSelectors.sliderStartStop);
        console.log('$slider',$slider);

        $slider.closest(defaultSelectors.sliderContainer).find(defaultSelectors.sliderStartStop).click(function(){
            console.log('click');
            if (swiper.autoplay.paused){
                $(this).removeClass('is-paused');
                swiper.autoplay.run();
            }else{
                $(this).addClass('is-paused');
                swiper.autoplay.pause();
            }
        });

    });
}

export function preloadSlide(slick, slideIndex) {
    let slideToPreload = slick.$slides[slideIndex];

    if (slideToPreload) {
        let $imgToPreload = $(slideToPreload).find('.js-lazy-img');
        if ($imgToPreload && $imgToPreload.length) {
            loadImg($imgToPreload);
        }
    }
}

export function preloadNSlide(slick, start, n) {
    for (let i = 0; i < n; i++) {
        preloadSlide(slick, start + n);
    }
}


export const initInScope = createInitInScope();
