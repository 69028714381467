"use strict";

import $ from 'jquery';
import {onEnterViewPort} from "@elements/viewport-utils";
import {getPrefixedDataSet} from "@elements/data-set-utils";
import throwError from "@elements/throw-error";


const defaultSelectors = {
    base: '.js-lazy-img',
    backgroundModifier: '.js-lazy-img--bg'
};

const defaultOptions = {
    offset: window.innerHeight / 2,
    preventNative: false
};

export const initInScope = createInitInScope();

export function createInitInScope(selectors = defaultSelectors, options = defaultOptions) {
    return function ($scope) {
        let $images = $scope.find(selectors.base);

        $images = $images.map((_, el) => {
            return $(el).closest('picture').length > 0 ? $(el).closest('picture') : $(el);
        });

        if ('loading' in HTMLImageElement.prototype && !options.preventNative) {
            $images.each((_, el) => {
                const $image = $(el);

                if ($image.is(selectors.backgroundModifier)) {
                    onEnterViewPort($image, function (img) {
                        loadImg($(img), selectors, options);
                    }, options);
                } else {
                    switchImage($image, true)
                }

            })
        } else {
            $images.each((_, el) => {
                const $image = $(el);

                let elementOptions = {
                    ...defaultOptions,
                    ...options,
                    ...getPrefixedDataSet('lazy-img', $image)
                };

                onEnterViewPort($image, function (img) {
                    loadImg($(img), selectors, elementOptions);
                }, elementOptions);
            });
        }

        return $images;
    };
}

export function loadImg($img, selectors = {}, passedOptions = {}) {
    selectors = {...defaultSelectors, ...selectors};
    let options = {
        ...defaultOptions,
        ...getPrefixedDataSet('lazy-img', $img),
        ...passedOptions,
    };

    if ($img.is(selectors.backgroundModifier)) {
        if (options.mediaQueryBackgrounds) {
            // throwError('test');
            if (typeof options.mediaQueryBackgrounds !== "object") {
                throwError(mediaQueryBackgroundErrorMessage);
                return;
            }

            let mqs = Object.keys(options.mediaQueryBackgrounds);

            const setCurrentlyMatchingBackground = () => {
                let result = [...mqs].reverse().find(mq => mq === 'default' || matchMedia(mq).matches);

                setBackgroundImage($img, result
                    ? options.mediaQueryBackgrounds[result]
                    : options.background
                );
            };

            setCurrentlyMatchingBackground();

            mqs.filter(x => x !== 'default').forEach(mq => {
                // Use addListener instead of addEventListener because of IE
                matchMedia(mq).addListener(() => {
                    setCurrentlyMatchingBackground();
                });
            })
        } else {
            // single background image
            setBackgroundImage($img, options.background);
        }
    } else {
        // usual image
        switchImage($img);

        if (!window.HTMLPictureElement) {
            import('picturefill').then(function () {
                picturefill({
                    reevaluate: true
                });
            });
        }
    }
}

function setBackgroundImage($element, imageSrc) {
    $element.css('background-image', imageSrc
        ? `url("${imageSrc}")`
        : ''
    );
}

function switchImage($img, addLazyLoading = false) {
    if ($img.is('picture')) {
        $img.find('source, img').each(function () {
            if (addLazyLoading) {
                $img.find('img').attr('loading', 'lazy')
            }
            if ($(this).data('srcset')) {
                $(this).attr('srcset', $(this).data('srcset'));
            }

            if ($(this).data('src')) {
                $(this).attr('src', $(this).data('src'));
            }else if($(this).data('srcset')){
                let srcsetArray = $(this).data('srcset').split(" ");
                $(this).attr('src', srcsetArray[0]);
            }
        });
    } else {
        if (addLazyLoading) {
            $img.attr('loading', 'lazy');
        }

        if ($img.data('srcset')) {
            $img.attr('srcset', $img.data('srcset'));
        }

        if ($img.data('src')) {
            $img.attr('src', $img.data('src'));
        }else if($(this).data('srcset')){
            let srcsetArray = $(this).data('srcset').split(" ");
            $(this).attr('src', srcsetArray[0]);
        }
    }
}

const mediaQueryBackgroundErrorMessage = `Lazy loading mediaQueryBackgrounds error: data-media-query-backgrounds has to be a a valid JSON object. Most likely you used single quotes instead of double quotes for the JSON fields.
Valid Example: 
data-lazy-img-media-query-backgrounds='{
    "(min-width: 768px)": "http://placehold.it/1900x500?text=(min-width: 768px)",
    "(min-width: 1200px)": "http://placehold.it/1900x500?text=(min-width: 1200px)"
 }' `;