"use strict";

import { getPrefixedDataSet } from '@elements/data-set-utils';
import initModulesInScope from "@elements/init-modules-in-scope";

const defaultSelectors = {
    base: '.js-consent-overlay__wrapper',
    template: '.js-consent-overlay__template',
    button: '.js-consent-overlay__btn',
    dataset: 'consent'
};

export function initInScope($scope) {
    $scope.find(defaultSelectors.base).each(function () {
        createConsentOverlay(this, {revalidateEvent: 'onUpdateServices'}, defaultSelectors);
    });
}

function createConsentOverlay(baseElement, options, selectors) {
    let prefixData = getPrefixedDataSet(selectors.dataset, $(baseElement))
    let button = $(baseElement).find(selectors.button);


    $(window).on('UC_UI_INITIALIZED', function () {
        if (prefixData.lightbox) {
            $($(baseElement).closest('.js-lightbox')).on('onAfterSlide.lg', function () {
                let $currentItem = $('.lg').find('.lg-current');
                setTimeout(function () {
                    let $overlay = $currentItem.find(selectors.base);
                    if ($overlay.length > 0) {
                        if (window.UC_UI.getServicesBaseInfo().find(data => (data.id === prefixData.service)).consent.status) {
                            console.log('render')
                            renderTemplate(prefixData.renderItem, baseElement,  {base: selectors.base, template: selectors.template});
                        } else if ($(baseElement).attr('hidden')) {
                            $(baseElement).removeAttr('hidden');
                        }
                        initModulesInScope($overlay.parent());
                    }
                }, 200);
            })
        }

        $(window).on('UC_UI_CMP_EVENT', function () {
            if (window.UC_UI.getServicesBaseInfo().find(data => (data.id === prefixData.service)).consent.status) {
                renderTemplate(prefixData.renderItem, baseElement,  {base: selectors.base, template: selectors.template});
            } else if ($(baseElement).attr('hidden')) {
                $(baseElement).removeAttr('hidden');
            }
        });


        if (window.UC_UI.getServicesBaseInfo().find(data => (data.id === prefixData.service)).consent.status) {
            renderTemplate(prefixData.renderItem, baseElement,  {base: selectors.base, template: selectors.template});
        } else if ($(baseElement).attr('hidden')) {
            $(baseElement).removeAttr('hidden');
        }
    });

    if(button){
        $(button).on('click', () => {
            window.UC_UI.acceptService(prefixData.service).then(() => {
                renderTemplate(prefixData.renderItem, baseElement,  {base: selectors.base, template: selectors.template});
            });
        });
    }
}


function renderTemplate(renderItem, baseElement, selectors) {
    let template = $(baseElement).find(selectors.template);

    if(template) {
        let parent = $(baseElement).parent();
        if (parent.length > 0) { // add this check to prevent parent being null
            //everything about the overlay will be overwritten
            $(parent).html($(template).html());
            if (renderItem) {
                initModulesInScope($(renderItem).parent());
            }
            else {
                initModulesInScope(parent)
            }
        }
    }
}
