import {getConfigValue, requireConfigValues} from '@elements/config-utils';
import initModulesInScope from '@elements/init-modules-in-scope';
import {showNotification, clearAll} from '@elements/alert-notification';
import 'url-search-params-polyfill'; // Edge Polyfill
import fetch from "@elements/fetch";
import formDataEntries from 'form-data-entries';
import {isParsleyForm, isValid} from "@elements/parsley-bootstrap-validation";
import {responseTracking} from "@elements/tracking";

const configName = '_cartConfig';
window.app = window.app || {};
window.app.modules =  window.app.modules || {};

let app = window.app;

let pendingRequest,
    $count,
    $listLoading,
    $listResult,
    $progressBarResult,
    timeoutHandle;

export function init(){
    $count = $('.js-cart__count');
    $listLoading = $('.js-cart__overlay-list-loading');
    $listResult = $('.js-cart__overlay-list-result');
    $progressBarResult = $('.js-cart__progress-bar-result');

    // initial cart list count
    if (($count && $count.length) ) {
        requireConfigValues(['cartOverlayUrl'], configName);

        $listLoading.attr('hidden', null);
        $listResult.attr('hidden', 'hidden');

        let request = fetch(getConfigValue('cartOverlayUrl', configName), {
            method: 'get',
        });

        showNotification(request);
        request.then(response => response.json())
            .then(result => {
                if (result.success) {
                    setCartListContent($listResult, $listLoading, result.cartListContent);
                    if (typeof result.count !== "undefined") {
                        setCount($count, result.count);
                    }
                }
            }).catch(e => {
            console.warn(e);
        });
    }
}
import * as swiper from './swiper';
export function initInScope($scope, {onAdd, onAddSucceed, onAddFailed} = {}) {

    let $cartForm = $scope.find('.js-cart__form');
    let $cartRemove = $scope.find('.js-cart__remove');
    let $cartLoading = $('.js-cart__loading');
    let $cartResult = $('.js-cart__result');
    let $reservationCartResult = $('.js-cart__reservation-result');
    let $notificationContainer = $('.js-notification__container');

    let $cartConfirm = $scope.find('.js-cart__confirm');
    let $cartATrust = $cartConfirm.find('.js-cart__a-trust');
    let $checkoutCartContentResult = $cartConfirm.find('.js-cart__checkout-cart-content-result');
    let $checkoutPriceBoxResult = $cartConfirm.find('.js-cart__checkout-price-box-result');

    if ($cartForm && $cartForm.length) {

        $cartForm.each(function () {
            let $thisForm = $(this);
            let formAction = $thisForm.attr('action');
            let $submitBtn = $thisForm.find('button[type="submit"], button:not([type])');
            let formTrigger = $thisForm.data('trigger') || 'submit';
            let emptyCart = $thisForm.data('empty-cart');
            let showCartModal;

            // If the clicked button has a formaction then this action will be use for the request
            $submitBtn.on('click', function (e) {
                if($(this).attr('formaction')){
                    formAction = $(this).attr('formaction');
                }else{
                    formAction = $thisForm.attr('action');
                }
            });

            setTimeout(function(){

                $thisForm.on(formTrigger, function (evt) {
                    evt.preventDefault();

                    if (isParsleyForm($thisForm) && !isValid($thisForm)) {
                        return;
                    }

                    let $thisSubmittedForm = $(this);
                    showCartModal = $(this).data('show-cart-modal');

                    const data = new URLSearchParams(formDataEntries($thisForm[0]));

                    $cartLoading.attr('hidden', null);

                    if(emptyCart){
                        $cartResult.attr('hidden', 'hidden');
                    }


                    let request = fetch(formAction, {
                        method: 'post',
                        body: data,
                    });


                    if (!matchMedia('(max-width: 767px)').matches) {
                        clearAll({
                            $container: $notificationContainer
                        });

                        showNotification(request, {
                            $container: $notificationContainer
                        });
                    }

                    console.log('request',request)
                    responseTracking(request);

                    request.then(response => response.json())
                        .then(result => {
                            if (result.success) {
                                if(showCartModal){
                                    $($thisSubmittedForm.data('modal-target')).modal();
                                }

                                let content = result.content || result.html;

                                $cartResult.empty().append(content);
                                if (content !== "") {
                                    $cartResult.attr('hidden', null);
                                    initModulesInScope($cartResult);
                                } else {
                                    $cartResult.attr('hidden', true);
                                }

                                $reservationCartResult.empty().append(result.reservationContent);
                                if (result.reservationContent !== "") {
                                    $reservationCartResult.attr('hidden', null);
                                    initModulesInScope($reservationCartResult);
                                } else {
                                    $reservationCartResult.attr('hidden', true);
                                }

                                setCartListContent($listResult, $listLoading, result.cartListContent);
                                setCartProgressBarContent($progressBarResult, result.progressBarContent);

                                if (typeof result.count !== "undefined") {
                                    setCount($count, result.count);
                                    $thisForm.addClass('is-added-to-cart');
                                    $count.closest('.js-overlay__toggle').bind('animationend webkitAnimationEnd oAnimationEnd MSAnimationEnd', function(){
                                        $(this).removeClass('nav-btn__is-animated');
                                    }).addClass('nav-btn__is-animated');
                                }

                            }

                            // if (!matchMedia('(max-width: 767px)').matches) {
                            //     setTimeout(function(){
                            //         $notificationContainer.empty();
                            //
                            //     }, 3500);
                            // }

                            $cartLoading.attr('hidden', 'hidden');

                        }).catch(e => {
                            console.warn(e);
                            $cartLoading.attr('hidden', 'hidden');
                            $cartResult.attr('hidden', 'hidden');
                        }
                    );
                });


            }, 500);
            app.modules.productContentTeaserSwiper = swiper.createInitInScope({
                    sliderContainer: '.js-swiper-product-content-teaser',
                    slider: '.js-swiper__slider',
                    sliderScrollbar: '.js-swiper__scrollbar',
                    sliderPrev: '.js-swiper__prev',
                    sliderNex: '.js-swiper__next'
                },
                {
                    loop: false,
                    slidesPerView: 2,
                    spaceBetween: 20,
                    scrollbar: {
                        findElement: $swiperContainer => $swiperContainer.find('.js-swiper__scrollbar'),
                        draggable: true,
                    },
                    navigation: {
                        findNextElement: $swiperContainer => $swiperContainer.find('.js-swiper__next'),
                        findPrevElement: $swiperContainer => $swiperContainer.find('.js-swiper__prev'),
                    },
                    breakpoints: {
                        // when window width is >= 768px
                        768: {
                            slidesPerView: 4,
                            spaceBetween: 40
                        }
                    },
                });

        });

        // remove product from cart
        $cartRemove.on('click', function (evt) {
            evt.preventDefault();
            let $form = $(this).closest('form'),
                trigger = $(this).data('trigger') || 'submit';


            $form.find('.js-cart__amount').val('0');
            $form.trigger(trigger);
        });

        $scope.find('.js-cart__add-all').on('click', function (e) {
            e.preventDefault();
            let data = new URLSearchParams();
            let formAction;
            let notificationType;
            let $form = $(this).closest('form');
            let showCartModal = $form.data('show-cart-modal');


            $('.js-cart__add-all-container .js-cart__form-add-all').each(function () {
                let $thisForm = $(this);

                for (const [name, value] of formDataEntries($thisForm[0])) {
                    data.append(`${name}`, `${value}`);
                }
            });

            if($(this).attr('formaction')){
                formAction = $(this).attr('formaction');
            }else{
                formAction = $form.attr('action');
            }

            let request = fetch(formAction, {
                method: 'post',
                body: data,
            });


            if (!matchMedia('(max-width: 767px)').matches) {
                clearAll({
                    $container: $notificationContainer
                });

                showNotification(request, {
                    $container: $notificationContainer
                });
            }

            request.then(response => response.json())
                .then(result => {
                    if (result.success) {

                        if(showCartModal){
                            $($form.data('modal-target')).modal();
                        }

                        let content = result.content || result.html;

                        if (content) {
                            $cartResult.empty().append(content);
                            $cartResult.attr('hidden', null);
                            initModulesInScope($cartResult);
                        }

                        setCartListContent($listResult, $listLoading, result.cartListContent);
                        setCartProgressBarContent($progressBarResult, result.progressBarContent);

                        if (typeof result.count !== "undefined") {
                            setCount($count, result.count);
                            $count.closest('.js-overlay__toggle').bind('animationend webkitAnimationEnd oAnimationEnd MSAnimationEnd', function(){
                                $(this).removeClass('nav-btn__is-animated');
                            }).addClass('nav-btn__is-animated');
                        }
                    }

                    // if (!matchMedia('(max-width: 767px)').matches) {
                    //     setTimeout(function(){
                    //         $notificationContainer.empty();
                    //
                    //     }, 3500);
                    // }


                    $cartLoading.attr('hidden', 'hidden');

                }).catch(e => {
                    console.warn(e);
                    $cartLoading.attr('hidden', 'hidden');
                    $cartResult.attr('hidden', 'hidden');
                }
            );
        });
    }

    if ($cartATrust.length > 0) {
        $cartATrust.on('change', function (evt) {
            evt.preventDefault();

            $cartLoading.attr('hidden', null);

            const data = new URLSearchParams(formDataEntries($cartATrust[0]));

            let request = fetch($cartATrust.attr('action'), {
                method: 'post',
                body: data,
            });


            if (!matchMedia('(max-width: 767px)').matches) {
                clearAll({
                    $container: $notificationContainer
                });

                showNotification(request, {
                    $container: $notificationContainer
                });
            }

            request.then(response => response.json())
                .then(result => {
                    if (result.success) {

                        if (result.cartContent) {
                            $checkoutCartContentResult.empty().append(result.cartContent);
                            initModulesInScope($checkoutCartContentResult);
                        }

                        if (result.priceBoxContent) {
                            $checkoutPriceBoxResult.empty().append(result.priceBoxContent);
                            initModulesInScope($checkoutPriceBoxResult);
                        }
                    }

                    $cartLoading.attr('hidden', 'hidden');

                }).catch(e => {
                    console.warn(e);
                    $cartLoading.attr('hidden', 'hidden');
                    $cartResult.attr('hidden', 'hidden');
                }
            );
        })
    }
}

function setCount($element, count) {
    if (count) {
        $element.attr('hidden', null);
        $element.text(count);
    } else {
        $element.attr('hidden', 'hidden');
    }
}

function setCartListContent($listResult, $listLoading, content) {
    if (content) {
        $listResult.empty().append(content);
        $listLoading.attr('hidden', 'hidden');
        $listResult.attr('hidden', null);
        initModulesInScope($listResult);
    } else {
        $listLoading.attr('hidden', 'hidden');
        $listResult.attr('hidden', 'hidden');
    }
}

function setCartProgressBarContent($progressBarResult, content){
    if (content){
        $progressBarResult.empty().append(content);
    } else {
        $progressBarResult.attr('hidden', 'hidden');
    }
}