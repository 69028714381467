"use strict";

export function initInScope ($scope) {
    let $shariff = $scope.find('.js-shariff');

    if ($shariff && $shariff.length) {
        import('shariff').then(function () {
            new Shariff($shariff, {});
        });
    }
}