"use strict";

export function initInScope($scope) {
    let $searchBar = $scope.find('.js-search-bar');

    $searchBar.each(function () {
        let $this = $(this);
        let $searchIcon = $this.find('.js-search-icon');
        let $searchInput = $this.find('.js-search-input');
        let $removeIcon = $this.find('.js-search-remove');
        $removeIcon.hide();

        $searchInput.on('input focus', function () {
            let $searchText = $searchInput.val();
            if ($searchText.length > 0) {
                $removeIcon.show();
            } else {
                $removeIcon.hide();
            }
        });

        $removeIcon.on('click', function () {
            $searchInput.val('');
            $removeIcon.hide();
        });

        $searchIcon.on('click', function () {
            let $searchText = $searchInput.val();
            if ($searchText.length > 0) {
                $(this).closest('form').submit();
            }
        });
    });
}