"use strict";

import {onEnterViewPort} from "@elements/viewport-utils";
import initModulesInScope from '@elements/init-modules-in-scope';

export function initInScope($scope) {

    onEnterViewPort($scope.find('.js-render-template'), function () {

        let $template = $(this).find('.js-render-template__template');
        let templateHtml = $template.html();

        $(this).html(templateHtml).removeClass('js-render-template');
        $template.remove();
        initModulesInScope($(this));
    }, { offset: 300 });

}
