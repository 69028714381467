import $ from 'jquery';

import 'popper.js';
import 'bootstrap/js/dist/alert';
// import 'bootstrap/js/dist/button';
// import 'bootstrap/js/dist/carousel';
// import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/modal';
// import 'bootstrap/js/dist/popover';
// import 'bootstrap/js/dist/scrollspy';
// import 'bootstrap/js/dist/tab';
// import 'bootstrap/js/dist/toast';
import 'bootstrap/js/dist/tooltip';

let _config = window['_config'] || {};

window.app = window.app || {};
window.app.modules =  window.app.modules || {};

let app = window.app;


import initModulesInScope from "@elements/init-modules-in-scope";
app.initModules = function ($scope) {
    console.warn('\'app.initModules\' is going to be deprecated and should not be used anymore. Please import and use \'initModulesInScope\' from \'./scripts/initModulesInScope\' instead.');
    initModulesInScope($scope);
};

$.loadScript = function (url) {
    console.warn('\'$.loadScript\' is going to be deprecated and should not be used anymore. Please use \'import\' for local files or import and use \'loadScript\' for external resources.');

    return import('@elements/load-script').then(function (loadScript) {
        return loadScript.default(url);
    });
};

import './scripts/common';

/**********************************
 *
 *      Site Stuff
 *
 * ********************************/

import * as swiper from './scripts/swiper';

app.modules.productContentTeaserSwiper = swiper.createInitInScope({
        sliderContainer: '.js-swiper-product-content-teaser',
        slider: '.js-swiper__slider',
        sliderScrollbar: '.js-swiper__scrollbar',
        sliderPrev: '.js-swiper__prev',
        sliderNex: '.js-swiper__next'
    },
    {
        loop: false,
        slidesPerView: 2,
        spaceBetween: 20,
        scrollbar: {
            findElement: $swiperContainer => $swiperContainer.find('.js-swiper__scrollbar'),
            draggable: true,
        },
        navigation: {
            findNextElement: $swiperContainer => $swiperContainer.find('.js-swiper__next'),
            findPrevElement: $swiperContainer => $swiperContainer.find('.js-swiper__prev'),
        },
        breakpoints: {
            // when window width is >= 768px
            768: {
                slidesPerView: 4,
                spaceBetween: 40
            }
        }
    });

app.modules.contentTeaserSwiper = swiper.createInitInScope({
        sliderContainer: '.js-swiper-content-teaser',
        slider: '.js-swiper__slider',
        sliderScrollbar: '.js-swiper__scrollbar',
        sliderPrev: '.js-swiper__prev',
        sliderNex: '.js-swiper__next'
    },
    {
        loop: false,
        slidesPerView: 1.2,
        spaceBetween: 20,
        scrollbar: {
            findElement: $swiperContainer => $swiperContainer.find('.js-swiper__scrollbar'),
            draggable: true,
        },
        navigation: {
            findNextElement: $swiperContainer => $swiperContainer.find('.js-swiper__next'),
            findPrevElement: $swiperContainer => $swiperContainer.find('.js-swiper__prev'),
        },
        breakpoints: {
            // when window width is >= 768px
            768: {
                slidesPerView: 3,
                spaceBetween: 40,
                allowTouchMove:false
            }
        }
    });


app.modules.teaserSwiper = swiper.createInitInScope({
        sliderContainer: '.js-swiper-teaser',
        slider: '.js-swiper__slider',
        sliderScrollbar: '.js-swiper__scrollbar',
        sliderPrev: '.js-swiper__prev',
        sliderNex: '.js-swiper__next'
    },
    {
        loop: false,
        scrollbar: {
            findElement: $swiperContainer => $swiperContainer.find('.js-swiper__scrollbar'),
            draggable: true,
        },
        navigation: {
            findNextElement: $swiperContainer => $swiperContainer.find('.js-swiper__next'),
            findPrevElement: $swiperContainer => $swiperContainer.find('.js-swiper__prev'),
        }
    });


app.modules.uspsSwiper = swiper.createInitInScope({
        sliderContainer: '.js-swiper-usps',
        slider: '.js-swiper__slider',
        sliderScrollbar: '.js-swiper__scrollbar',
        sliderPrev: '.js-swiper__prev',
        sliderNex: '.js-swiper__next'
    },
    {
        loop: false,
        slidesPerView: 2,
        navigation: {
            findNextElement: $swiperContainer => $swiperContainer.find('.js-swiper__next'),
            findPrevElement: $swiperContainer => $swiperContainer.find('.js-swiper__prev'),
        },
        breakpoints: {
            // when window width is >= 768px
            768: {
                slidesPerView: 4,
                spaceBetween: 40,
                allowTouchMove:false
            }
        }
    });


app.modules.brandsSwiper = swiper.createInitInScope({
        sliderContainer: '.js-swiper-brands',
        slider: '.js-swiper__slider',
        sliderScrollbar: '.js-swiper__scrollbar',
        sliderPrev: '.js-swiper__prev',
        sliderNex: '.js-swiper__next'
    },
    {
        loop: false,
        allowTouchMove:false,
        slidesPerView: 2.4,
        spaceBetween: 20,
        navigation: {
            findNextElement: $swiperContainer => $swiperContainer.find('.js-swiper__next'),
            findPrevElement: $swiperContainer => $swiperContainer.find('.js-swiper__prev'),
        },
        breakpoints: {
            // when window width is >= 768px
            768: {
                slidesPerView: 6,
                spaceBetween: 20,
                allowTouchMove:false
            }
        }
    });

app.modules.topBrandsSwiper = swiper.createInitInScope({
        sliderContainer: '.js-swiper-top-brands',
        slider: '.js-swiper__slider',
        sliderScrollbar: '.js-swiper__scrollbar',
        sliderPrev: '.js-swiper__prev',
        sliderNex: '.js-swiper__next'
    },
    {
        loop: false,
        slidesPerView: 1.2,
        spaceBetween: 20,
        navigation: {
            findNextElement: $swiperContainer => $swiperContainer.find('.js-swiper__next'),
            findPrevElement: $swiperContainer => $swiperContainer.find('.js-swiper__prev'),
        },
        breakpoints: {
            // when window width is >= 768px
            768: {
                slidesPerView: 3,
                spaceBetween: 20,
                allowTouchMove:false,
            }
        }
    });

import * as imgTextSwiper from './scripts/img-text-swiper';
app.modules.imgTextSwiper = imgTextSwiper.createInitInScope({
        sliderContainer: '.js-swiper-img-text',
        slider: '.js-swiper__slider',
        sliderScrollbar: '.js-swiper__scrollbar',
        sliderPrev: '.js-swiper__prev',
        sliderNex: '.js-swiper__next'
    },
    {
        loop: false,
        slidesPerView: 1,
        effect: 'fade',
        fadeEffect: {
            crossFade: true
        },
        autoplay: {
            delay: 3000,
            disableOnInteraction:false
        },
        navigation: {
            findNextElement: $swiperContainer => $swiperContainer.find('.js-swiper__next'),
            findPrevElement: $swiperContainer => $swiperContainer.find('.js-swiper__prev'),
        },
        pagination: {
            findElement: $swiperContainer => $swiperContainer.find('.js-swiper__pagination'),
            clickable: true
        },
    });


import * as parsley from '@elements/parsley-bootstrap-validation';
import * as shariff from "./scripts/shariff";
app.modules.parsley = parsley.initInScope;


(function( $ ){
    "use strict";

    /* -> _config._preload = Load this functions first */
    if (_config['_preload']) {
        $.each( _config['_preload'], function( _key, _val ){
            if( typeof _val == 'boolean' && typeof window.app[_key] == 'function' ){
                window.app[_key]();
            }
        });
    }

    /* -> _config = Load all others (not _preload and _reload) */
    $.each( _config, function( _key, _val ){
        // console.log('key',_key)
        // console.log('val',_val)
        if( ( typeof _val == 'boolean' && typeof window.app[_key] == 'function' && _key != '_reload' && _key != '_preload' ) ){
            window.app[_key]();
        }
    });

    /* -> _config._reload = Load the ajaxInclued and others after the rest */
    if (_config['_reload']) {
        $.each( _config['_reload'], function( _key, _val ){
            if( ( typeof _val == 'boolean' && typeof window.app[_key] == 'function' ) ){
                window.app[_key]();
            }
        });
    }

    initModulesInScope();
})($);