'use strict';

import * as wishlistPackage from '@elements/wishlist';
import {createTooltipInitInScope, setListTooltip} from './toggle-to-list-tooltip';

let comparelist = null;
let clearNotification = 0;

if (window['_comparelist']) {
    comparelist = wishlistPackage.createWishlist({
        ...window['_comparelist'],
        notificationOptions: {
            $container: $('.js-notification__container')
        },
        translations: {
            add: 'comparelist.add',
            remove: 'comparelist.remove'
        }
    }, {
        item: '.js-comparelist__btn',
        itemId: 'comparelist-id',
        wishlistId: 'comparelist-list',
        itemActiveClass: 'is-active'
    });
}


export function init() {

    if(comparelist){
        comparelist.init();
    }

    $(document).on('renderd.wishlist', (evt ,params) => {
        if(params.list === "comparelist-list"){
            // update counter
            let $comparelistItemCounter = $('.js-comparelist__items-count');
            let $comparelistItemSignal = $('.js-comparelist__items-count-signal');

            if(params.activeIds){
                const count = params.activeIds.length;
                if(count <= 0) {
                    $comparelistItemCounter.text(count).attr('hidden', 'hidden');
                    $comparelistItemSignal.attr('data-comparelist', 0);
                    if($comparelistItemSignal.attr("data-wishlist") <= 0){
                        $comparelistItemSignal.attr('hidden', 'hidden');
                    }
                }else {
                    $comparelistItemCounter.text(count).attr('hidden', null);
                    $comparelistItemSignal.attr('data-comparelist', count);
                    $comparelistItemSignal.attr('hidden', null);
                }
            }
        }

    });


    $(document).on('added.wishlist', (evt ,params) => {
        if(params.list === "comparelist-list"){
            clearNotification++;

            $('.js-btn-profil').bind('animationend webkitAnimationEnd oAnimationEnd MSAnimationEnd', function(){
                $(this).removeClass('nav-btn__is-animated');
            }).addClass('nav-btn__is-animated');

            // // hide notification after some seconds
            setTimeout(function(){
                if(clearNotification === 1){
                    $('.js-notification__container').empty();
                }

                clearNotification--;

            }, 3500);

            setListTooltip($(evt.currentTarget.activeElement), true);

        }

    });

    $(document).on('removed.wishlist', (evt ,params) => {
        if(params.list === "comparelist-list"){
            clearNotification++;

            $('.js-btn-profil').bind('animationend webkitAnimationEnd oAnimationEnd MSAnimationEnd', function(){
                $(this).removeClass('nav-btn__is-animated');
            }).addClass('nav-btn__is-animated');

            // hide notification after some seconds
            setTimeout(function(){
                if(clearNotification === 1){
                    $('.js-notification__container').empty();
                }

                clearNotification--;

            }, 3500);

            setListTooltip($(evt.currentTarget.activeElement), true);

        }

    });


}

export function initInScope($scope) {
    if (comparelist) {
        comparelist.initInScope($scope);
        // removing in wishlist removes the whole item
        let $compareRemoveBtn = $scope.find('.js-comparelist__remove');
        $compareRemoveBtn.each((_, el) => {
            const $button  = $(el),
                id         = $button.data('comparelist-id');
            $button.on('click', function (e) {
                e.preventDefault();
                let _request = comparelist.remove(id, {showUndo: false});
                let $this = $(this);
                let $tabToUpdate = $($(this).data('tab-id'));
                let $wishlistTable = $this.closest('.js-wishlist__table');
                _request.then(function wishlistTableItemsCount(res) {
                    if(res.status){

                        let wishlistTableItemsCount =  $wishlistTable.data('wishlist-table-items');
                        wishlistTableItemsCount = parseInt(wishlistTableItemsCount)-1;
                        $wishlistTable.data('wishlist-table-items',wishlistTableItemsCount);

                        $tabToUpdate.find('.nav-link__number').text(wishlistTableItemsCount);

                        if(wishlistTableItemsCount === 0){
                            location.reload();
                        }else{
                            let firstTr =  $this.closest('.table--compare').find('tbody tr:first');
                            $('.js-comparelist__item-td').each((_, el) => {
                                if($(el).data('comparelist-id') === id){
                                    $(el).remove();
                                }
                            });
                            firstTr.append('<td rowspan="100"></td>');
                        }

                    }
                });
            });
        });
    }
}
