"use strict";


export function initInScope($scope) {
    document.addEventListener('flAutocompleteReady', function (e) {
        e.detail.flAutocomplete.selectItem = function (event, ui) {
            // The item being selected:
            var item = ui.item;

            let formAction = $('.js-findologic-form').attr('action');

            if(item.url){
                location.href = item.url;
            }else{
                location.href = formAction + '?' + item.block + '[]=' + encodeURIComponent(item.value);
            }
        };
    }, false);


    let $findologicSearch = $scope.find('.js-findologic-search');

    $findologicSearch.on('submit', function (e) {

        let $findologicOrder = $scope.find('.js-findologic-search__order');

        if ($findologicOrder.val()){
            $findologicOrder.attr('name',$findologicOrder.data('name'))
        }

        let $findologicPerPage = $scope.find('.js-findologic-search__perPage');

        if ($findologicPerPage.val()){
            $findologicPerPage.attr('name',$findologicPerPage.data('name'))
        }
    });
}
