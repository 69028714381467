"use strict";

import initModulesInScope from '@elements/init-modules-in-scope';

export function initInScope($scope) {

    if (matchMedia('(max-width: 767px)').matches) {

        $scope.find('.js-nav__toggle').on('click', function (e) {
            let $navToggle = $(this),
                $navTarget = $($navToggle.data('target'));

            $('.js-nav__toggle').not(this).removeClass('is-open');
            $('.js-nav__overlay-menu').not($navTarget).removeClass('is-open');

            if($navToggle.hasClass('is-open')){
                $navToggle.removeClass('is-open');
                $navTarget.removeClass('is-open');
                $('body').removeClass('nav-open');
            }else{
                $navToggle.addClass('is-open');
                $navTarget.addClass('is-open');
                $('body').addClass('nav-open');
            }

        });


        $scope.find('.js-nav__link').on('click', function (evt) {
            let $currentLink = $(this);
            let $nav = $currentLink.closest('.js-nav');
            let $navItems = $nav.find('.js-nav__item');
            let $navItem = $currentLink.closest('.js-nav__item');

            evt.preventDefault();

            if($navItem.find('.navbar-overlay').hasClass('is-open')) {
                $navItem.find('.navbar-overlay').removeClass('is-open');
            } else {
                $navItem.find('.navbar-overlay').addClass('is-open');
            }
        });


        $scope.find('.js-nav__go-back').on('click', function() {
            let $goBack = $(this);
            let $navItem = $goBack.closest('.js-nav__item');
            $navItem.find('.navbar-overlay').removeClass('is-open');
        });
    }
}
