"use strict";

import {onEnterViewPort} from "@elements/viewport-utils";
import initModulesInScope from '@elements/init-modules-in-scope';


const defaultSelectors = {
    nav: '.js-nav',
    item: '.js-nav__item',
    subnav: '.js-nav__subnav'
};

const selectors = {};

export function createInitInScope(selectors = defaultSelectors) {
    return function ($scope) {

        let $nav = $scope.find(selectors.nav),
            $navItems = $scope.find(selectors.item),
            $navLinks = $scope.find(selectors.item).children('a');

        if (matchMedia('(min-width: 768px)').matches) {
            let timeout = 300;
            let timer;
            let closeTimeout = 50;

            $nav.each(function (e) {
                let $thisNav = $(this),
                    $submenu = $thisNav.find(selectors.subnav),
                    $items = $thisNav.children(selectors.item);

                $items.on('click', function() {
                    let $element = $(this);
                    clearTimeout(timer);
                    let openTimeout = ($items.hasClass('is-open')) ? timeout : 100;

                    if($element.hasClass('is-open') && $('body').hasClass('nav-open')){
                        clearTimeout(timer);
                        timer = setTimeout(function() {
                            $items.removeClass('is-open');
                            $('body').removeClass('nav-open');
                        }, closeTimeout);
                    }
                    else{
                        timer = setTimeout(function() {
                            $items.removeClass('is-open');
                            $element.addClass('is-open');
                            $('body').addClass('nav-open');
                        }, openTimeout);

                    }
                });

                $('body').on('click', function (evt){
                    if($items.hasClass('is-open') && $('body').hasClass('nav-open') && $(evt.target).parents('.js-nav').length < 1){
                        evt.preventDefault();
                        clearTimeout(timer);
                        timer = setTimeout(function() {
                            $items.removeClass('is-open');
                            $('body').removeClass('nav-open');
                        }, closeTimeout);
                    }
                });
            });

            $navLinks.on('keydown', function (evt) {
                if(evt.originalEvent.code === 'Space' || evt.originalEvent.code === 'ArrowDown' || evt.originalEvent.code === 'ArrowUp'){
                    evt.preventDefault();
                    $(this).closest(selectors.nav).find(selectors.item).removeClass('is-open');
                    $(this).closest(selectors.item).addClass('is-open');

                    $('body').on('keydown', escKeyHandler);
                } else if (evt.originalEvent.code === 'Tab' && !$(this).parents('.js-nav__item').hasClass('is-open')) {
                    $(selectors.item).removeClass('is-open');
                    $('body').off('keydown', escKeyHandler);
                }
            });
        }

    }
}

function escKeyHandler (evt){
    if(evt.originalEvent.code === 'Escape'){
        $(selectors.nav).removeClass('is-open');
        $('body').off('keydown', escKeyHandler);
    }
}

export const initInScope = createInitInScope();
