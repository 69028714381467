"use strict";

export function createTooltipInitInScope($scope) {
    $scope.find('.js-list__tooltip').each(function () {
        setListTooltip($(this), false);
    });
}

export function setListTooltip ($this, reInit) {
    $this.tooltip('dispose');
    $this.tooltip({
        title: $this.attr('title')
    });
}

