"use strict";

export function initInScope ($scope) {
    $scope.find('.js-collapsing-radio__input').on('change',function (e) {
        if ($(this).closest('.js-collapsing-radio').find('.js-collapsing-radio__input--toggler').prop('checked')) {
            $(this).closest('.js-collapsing-radio').find('.js-collapsing-radio__add').removeAttr('hidden');
        } else {
            $(this).closest('.js-collapsing-radio').find('.js-collapsing-radio__add').attr('hidden','hidden');
            $(this).closest('.js-collapsing-radio').find('.js-collapsing-radio__add').find('input').val('');
        }
    });
}