'use strict';


export function initInScope($scope) {

    let $reservation = $scope.find('.js-reservation'),
        $reservationCheckbox = $reservation.find('.js-reservation__checkbox');

    $reservationCheckbox.on('change', function (e){
       let $reservationSelectWrapper = $(this).closest('.js-reservation').find('.js-reservation__select-wrapper');
       let $reservationSubmitBtn = $(this).closest('.js-reservation').find('.js-reservation__btn');

       if($(this).is(':checked')){
           $reservationSubmitBtn.prop('disabled', false);
           $reservationSelectWrapper.removeClass('disabled');
           $reservationSubmitBtn.removeClass('disabled');
       }else{
           $reservationSubmitBtn.prop('disabled', true);
           $reservationSelectWrapper.addClass('disabled');
           $reservationSubmitBtn.addClass('disabled');
       }
    });
}