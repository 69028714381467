"use strict";

export function initInScope ($scope) {
    // tooltip init
    $scope.find('.js-tooltip').tooltip();

    // prevent bootstrap dropdown from closing when clicking inside
    $scope.find('.js-dropdown-stop-propagation').on("click.bs.dropdown", function (e) {
        e.stopPropagation(); e.preventDefault();
    });

    $scope.find('.js-scroll-to-top').on('click', function (e) {
        try {
            window.scroll({
                top: 0,
                behavior: 'smooth'
            });
        } catch (e) {
            window.scrollTo(0, 0);
        }
    });
}