'use strict';

import throwError from '@elements/throw-error'
import fetch from "@elements/fetch";
import initModulesInScope from "@elements/init-modules-in-scope";
import 'url-search-params-polyfill'; // Edge Polyfill
import 'url-polyfill';


/**
 This module opens a Lightbox for newsletter automatically when mouse is outside the document (yes.. there are better solutions and apis, but this is an explicit customer wish...).
 For mobile it displays the Newsletter lightbox after 30s.

 When closed a parameter in session storage is written. The customer can define the time in minutes when it comes again.
 */
let popinModalUrl;
let popinTimer;

export function init() {
    if(typeof Storage === "undefined"){
        throwError('cannot access localStorage - sheep stories not loaded');
        return
    }
    /**
     * check if already dissmissed
     */
    if(window.localStorage.getItem('popinModalDisabled') !== null){
        const itemStr = localStorage.getItem('popinModalDisabled');

        const item = JSON.parse(itemStr);
        const now = new Date();
        // compare the expiry time of the item with the current time
        if (now.getTime() > item.expiry) {
            // If the item is expired, delete the item from storage
            // and return null
            localStorage.removeItem('popinModalDisabled');
            popinModalUrl = _config['popinModalUrl'];

            if(!popinModalUrl){
                return;
            }

            initPopinModal();

        }
    }else{
        const itemStr = localStorage.getItem('popinModalDisabled');
        // if the item doesn't exist, return null
        if (!itemStr) {
            /**
             * the popinModalUrl is a predefined property that can be set on every page.
             */
            popinModalUrl = _config['popinModalUrl'];

            if(!popinModalUrl){
                return;
            }

            initPopinModal();
        }
    }

}

function initPopinModal() {
    let $popinModal = $('.js-popin-modal'),
        $popinLoading = $('.js-popin-modal__loading'),
        $popinResult = $('.js-popin-modal__result'),
        $popinNotification = $('.js-popin-modal__notifications');

    $popinLoading.attr('hidden', null);

    fetch(popinModalUrl).then((res) => {
        return res.clone().json();
    }).then((res) => {
        if(res.success && res.content){


            $popinLoading.attr('hidden', 'hidden');

            const $content = $(res.content),
                $closeBtn = $content.find('.js-popin-modal__close');

            $popinResult.append($content).attr('hidden', null);

            initModulesInScope($content);

            if(_config['popinExitIntent'] && !_config['popinExitIntentMobileDisable']){
                let popinExitIntentMobileSeconds = _config['popinExitIntentMobileSeconds'] || 10;

                if(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)){

                    popinTimer = setTimeout(
                        () => {
                            $popinModal.modal('show');
                        }, popinExitIntentMobileSeconds * 1000);

                }else{
                    $(document).on('mouseleave.popinModal',(e) => {
                        //if e.relatedTarget === null there is no next element for mouse -> outside the window
                        //otherwise mouseleave could also be a bubbled event from bottom tree
                        if(e.relatedTarget === null){
                            $popinModal.modal('show');
                        }
                    });
                }
            }else if(matchMedia('(min-width: 768px)').matches){

                if(_config['popinExitIntent']){
                    $(document).on('mouseleave.popinModal',(e) => {
                        //if e.relatedTarget === null there is no next element for mouse -> outside the window
                        //otherwise mouseleave could also be a bubbled event from bottom tree
                        if(e.relatedTarget === null){
                            $popinModal.modal('show');
                        }
                    });
                }else{
                    let popinModalSecond = _config['popinSeconds'] || 5;

                    popinTimer = setTimeout(
                        () => {
                            $popinModal.modal('show');
                        }, popinModalSecond * 1000);
                }

            }else if(!_config['popinExitIntentMobileDisable']){

                let popinModalSecond = _config['popinSeconds'] || 5;

                popinTimer = setTimeout(
                    () => {
                        $popinModal.modal('show');
                    }, popinModalSecond * 1000);
            }

            $popinModal.on('hidden.bs.modal', () => {
                removePopinModal($popinModal);
            });
            $popinModal.on('hide.bs.modal', () => {
                disablePopinModal();
            });

            $closeBtn.on('click', () => {
                $popinModal.modal('hide');
            })

        }
    })
}

export function disablePopinModal() {
    if(popinTimer){
        clearTimeout(popinTimer);
    }
    
    $(document).off('mouseleave.popinModal');

    let popinNextPopinTimeMs = _config['popinNextPopinTimeMinutes'] * 60 * 1000;

    if (!popinNextPopinTimeMs){
        popinNextPopinTimeMs = 1440 * 60 * 1000; // 24h
    }

    const now = new Date();
    const item = {
        value: true,
        expiry: now.getTime() + popinNextPopinTimeMs,
    };
    localStorage.setItem('popinModalDisabled', JSON.stringify(item))

}

export function removePopinModal($scope = $('body')) {
    const $modal = $scope.hasClass('.js-modal-popin') ? $scope : $scope.find('.js-modal-popin');
    if($modal.hasClass('show')){
        $modal.modal('hide');
    }
    $modal.remove();
}