import * as wishlistPackage from '@elements/wishlist';
import initModulesInScope from "@elements/init-modules-in-scope";

let myWishlist = null;

let wishlist = null;
let clearNotification = 0;

if (window['_wishlist']) {
    wishlist = wishlistPackage.createWishlist({
        ...window['_wishlist'],
        notificationOptions: {
            $container: $('.js-notification__container')
        }
    }, {
        item: '.js-wishlist__btn',
        itemId: 'wishlist-id',
        wishlistId: 'wishlist-list',
        itemActiveClass: 'is-active'
    });
}

let myWishlists = [];
if (window['_wishlists']) {
    for(let i = 0; i < window['_wishlists'].length; i++){
        myWishlists.push(wishlistPackage.createWishlist({
            ...window['_wishlists'][i],
            notificationOptions: {
                $container: $('.js-notification__container'),
            }
        }, {
            item: '.js-wishlist-' + i + '__btn',
            itemId: 'wishlist-id',
            wishlistId: 'wishlist-list',
            itemActiveClass: 'is-active'
        }));
    }
}


export function init() {
    if (myWishlists.length) {
        myWishlists.forEach((wishlist) => {
            wishlist.init();
        });
    }
    if(wishlist){
        wishlist.init();
    }


    $(document).on('renderd.wishlist', (evt ,params) => {
        if(params.list === "wishlist-list"){
            // update counter
            let $wishlistItemCounter = $('.js-wishlist__items-count');
            let $wishlistItemSignal = $('.js-wishlist__items-count-signal');

            if(params.activeIds){
                const count = params.activeIds.length;
                if(count <= 0) {
                    $wishlistItemCounter.text(count).attr('hidden', 'hidden');
                    $wishlistItemSignal.attr('data-wishlist', 0);
                    if($wishlistItemSignal.attr("data-comparelist") <= 0){
                        $wishlistItemSignal.attr('hidden', 'hidden');
                    }
                }else {
                    $wishlistItemCounter.text(count).attr('hidden', null);
                    $wishlistItemSignal.attr('data-wishlist', count);
                    $wishlistItemSignal.attr('hidden', null);
                }
            }
        }

    });


    $(document).on('added.wishlist', (evt ,params) => {
        if(params.list === "wishlist-list"){
            clearNotification++;

            $('.js-btn-profil').bind('animationend webkitAnimationEnd oAnimationEnd MSAnimationEnd', function(){
                $(this).removeClass('nav-btn__is-animated');
            }).addClass('nav-btn__is-animated');

            // hide notification after some seconds
            setTimeout(function(){
                if(clearNotification === 1){
                    $('.js-notification__container').empty();
                }

                clearNotification--;

            }, 3500);
        }

    });

    $(document).on('removed.wishlist', (evt ,params) => {
        if(params.list === "wishlist-list"){
            clearNotification++;

            $('.js-btn-profil').bind('animationend webkitAnimationEnd oAnimationEnd MSAnimationEnd', function(){
                $(this).removeClass('nav-btn__is-animated');
            }).addClass('nav-btn__is-animated');

            // hide notification after some seconds
            setTimeout(function(){
                if(clearNotification === 1){
                    $('.js-notification__container').empty();
                }

                clearNotification--;

            }, 3500);
        }

    });

}


export function initInScope($scope) {
    if (myWishlists.length) {
        myWishlists.forEach((wishlist) => {
            wishlist.initInScope($scope);

            let $wishListItem = $scope.find('.js-wishlist__item');
            $wishListItem.each((_, el) => {
                const $item    = $(el),
                    $button     = $item.find('.js-wishlist__remove'),
                    id          = $item.data('wishlist-id');

                $button.on('click', function (e) {
                    e.preventDefault();
                    let _request = wishlist.remove(id, {showUndo: true});
                    _request.then(function (res) {
                        if(res.success){
                            $container.remove();

                        }
                    })
                })
            })
        });
    }

    if (wishlist) {
        wishlist.initInScope($scope);

        let $wishlistModalBtn = $scope.find('.js-wishlist__remove-modal');

        $wishlistModalBtn.on('click', function (e) {

            let $item = $(this).closest('.js-wishlist__item');
            let $itemID = $item.data('wishlist-id');

            $scope.find('.js-wishlist__remove').data('wishlist-id', $itemID);

            initModulesInScope($scope.find('.js-wishlist__remove'));

            $('#modalConfirmWishlistRemove').modal('show');
        });


        $scope.find('.js-wishlist__remove').on('click', function (e) {

            let removeId = $(this).data('wishlist-id');
            let $wishListItem = $scope.find('.js-wishlist__item');

            $wishListItem.each((_, el) => {
                let $item    = $(el),
                      itemId   = $item.data('wishlist-id');

                if(itemId === removeId){
                    let _request = wishlist.remove(removeId, {showUndo: false});
                    _request.then(function (res) {
                        if(res.status){
                            $item.remove();
                        }
                    });
                }

            });

        });
    }

}